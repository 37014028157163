// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PreventPropagation, Space, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ItemActionButton from '@shared/design/components/Table/components/ItemActionButton';
import ItemActionMenuButton from '@shared/design/components/Table/components/ItemActionMenuButton';
import TableBuilder, {
  ColumnDefinitionType,
  ColumnDefinitionCellActionsType,
  ItemType,
  RowHookType,
  ItemFunctionReturnVoidType,
} from '@shared/design/components/Table/components/TableBuilder';

export type RowVerticalPaddingType = 8 | 12;

const CellRow = Styled.View<{
  rowVerticalPadding?: RowVerticalPaddingType;
}>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: ${({rowVerticalPadding}) => rowVerticalPadding || 8}px;
`;

const CellRowButton = Styled.ButtonV2<{
  rowVerticalPadding?: RowVerticalPaddingType;
  isHighlighted: boolean;
}>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: ${({rowVerticalPadding}) => rowVerticalPadding || 8}px;
  background-color: ${({isHighlighted}) => (isHighlighted ? colors.Blue10 : colors.white)};
  cursor: pointer;
`;
const TableRowPressHandler = ({
  rowHook = TableBuilder.PLACEHOLDER_ROW_HOOK,
  onRowPress,
  isRowSelected,
  rowVerticalPadding,
  item,
  children,
}: {
  onRowPress?: ItemFunctionReturnVoidType;
  rowHook?: RowHookType;
  isRowSelected: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  item: ItemType;
  children: React.ReactNode;
}) => {
  const {isHovered, ref} = useHover();
  const {isOpen, handleClose, key} = rowHook.hook(rowHook.hookArgument);

  return onRowPress ? (
    <React.Fragment>
      <CellRowButton
        ref={ref}
        onPress={() => onRowPress(item)}
        isHighlighted={isHovered || isRowSelected}
        rowVerticalPadding={rowVerticalPadding}
      >
        {children}
      </CellRowButton>
      {rowHook.renderComponent?.({isOpen, handleClose, hookKey: key})}
    </React.Fragment>
  ) : (
    <CellRow rowVerticalPadding={rowVerticalPadding}>{children}</CellRow>
  );
};

const ItemActions = ({
  actions,
  columnDefinition,
  item,
}: {
  actions: ColumnDefinitionCellActionsType['actions'];
  columnDefinition: ColumnDefinitionCellActionsType;
  item: ItemType;
}) => {
  const actionsList = actions ? actions(item) : [];
  const actionsForMenu = actionsList.filter(
    (action) => !action.desktopIcon && !action.desktopLabel,
  );
  const hasIndependentAndMenuActions = actionsList.length > actionsForMenu.length;
  return (
    <PreventPropagation style={{flexDirection: 'row'}}>
      {actionsList?.map((action, index) => {
        if (action.desktopIcon || action.desktopLabel) {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Space width={8} />}
              <ItemActionButton action={action} item={item} />
            </React.Fragment>
          );
        }
      })}
      {_.some(actionsForMenu) && (
        <React.Fragment>
          {hasIndependentAndMenuActions && <Space width={8} />}
          <ItemActionMenuButton columnDefinitions={[columnDefinition]} item={item} />
        </React.Fragment>
      )}
    </PreventPropagation>
  );
};

export interface TableRowProps {
  onRowPress?: ItemFunctionReturnVoidType;
  rowHook?: RowHookType;
  isRowSelected: boolean;
  rowVerticalPadding?: RowVerticalPaddingType;
  item: ItemType;
  rowIndex: number;
  columnDefinitions: ColumnDefinitionType[];
}
const TableRow = ({
  item,
  rowIndex,
  columnDefinitions,
  onRowPress,
  isRowSelected,
  rowVerticalPadding,
  rowHook,
}: TableRowProps) => {
  return (
    <TableRowPressHandler
      onRowPress={onRowPress}
      rowHook={rowHook}
      isRowSelected={isRowSelected}
      rowVerticalPadding={rowVerticalPadding}
      item={item}
    >
      {columnDefinitions.map((columnDefinition, index) => {
        const {flex, minWidth, maxWidth, width, cellStyle = {}} = columnDefinition;
        return (
          <TableBuilder.Cell key={index} style={{flex, minWidth, maxWidth, width, ...cellStyle}}>
            {TableBuilder.getColumnHasActions(columnDefinition) ? (
              <ItemActions
                columnDefinition={columnDefinition}
                item={item}
                actions={columnDefinition.actions}
              />
            ) : (
              <React.Fragment>
                <TableBuilder.DisplayContent
                  cellIcon={columnDefinition.cellIcon}
                  cellIconColor={columnDefinition.cellIconColor}
                  cellIconSize={columnDefinition.cellIconSize}
                  cellText={columnDefinition.cellText}
                  handlePressCellText={columnDefinition.handlePressCellText}
                  cellComponent={columnDefinition.cellComponent}
                  tooltip={columnDefinition.tooltip}
                  numberOfLines={columnDefinition.numberOfLines}
                  item={item}
                  rowIndex={rowIndex}
                />
                {columnDefinition.secondary && (
                  <React.Fragment>
                    <Space height={4} />
                    <TableBuilder.DisplayContent
                      cellText={columnDefinition.secondary.cellText}
                      handlePressCellText={columnDefinition.secondary.handlePressCellText}
                      cellComponent={columnDefinition.secondary.cellComponent}
                      tooltip={columnDefinition.secondary.tooltip}
                      numberOfLines={columnDefinition.secondary.numberOfLines}
                      item={item}
                      rowIndex={rowIndex}
                      isSecondary
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </TableBuilder.Cell>
        );
      })}
    </TableRowPressHandler>
  );
};

export default TableRow;
